/**
 * Global function for registering Seznam's "naseptavac" to input element.
 * @param {Array.<string>} e - Main input element (with searching address)
 * @param {string} u - Address input element which should be updated by SMap suggest
 * @param {string} o - City input element ..
 * @param {string} p - Postal code input element ..
 */
function registerNaseptavac(e, u, o, p) {
    e.forEach((inpt) => {
        $(inpt).autocomplete({
            delay: 500,
            classes: {
                "ui-autocomplete": "address-suggest"
            },
            source: (_val, response) => {
                const query = e.reduce((acc, el) => acc + (document.querySelector(el).value ? document.querySelector(el).value + "," : ""), "");

                $.nette.ajax({
                    type: "POST",
                    url: "?do=suggestAddress",
                    off: ['modals'],
                    data: {
                        query: query
                    },
                    success: (data) => {
                        if(data.items && data.items.length > 0) {
                            const result = data.items.map((address) => {
                                return {
                                    value: `${address.regionalStructure[1].name} ${address.regionalStructure[0].name}`,
                                    label: `${address.regionalStructure[3].name}${address.regionalStructure[2].name && address.regionalStructure[2].name.length > 0 ? ` - ${address.regionalStructure[2].name}` : ""}`,
                                    postal: `${address.zip}`
                                };
                            });

                            response(result);
                            return;
                        }

                        response([]);
                    }
                });
            },
            select: function( event, ui ) {
                document.querySelector(o).value = ui.item.label;
                document.querySelector(u).value = ui.item.value;
                document.querySelector(p).value = ui.item.postal;
         
                return false;
            }
        }).autocomplete("instance")._renderItem = function(ul, item) {
            return $(`<li>`)
                .append(`<div><span class="font-weight-bold">${item.value}</span><br>${item.postal} ${item.label}</div>`)
                .appendTo( ul );
        };
    });
}